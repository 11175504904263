<!-- Header Structure -->
<template>
  <div
    id="footer"
    class="sticky-footer bg-primary pt-5"
  >
    <!-- Main -->
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-12 col-sm-12">
         <router-link to="/">
            <img
            class="footer-logo"
            src="/static/images/thriggle.png"
            width="180"
            alt=""
          >
         </router-link>
          <br><br>
          <ul class="footer-links">
            <li>
              <router-link
                class="text-white"
                to="/terms"
              >
                Terms of Use
              </router-link>
            </li>
            <li>
              <router-link
                class="text-white"
                to="/abuse"
              >
                Terms of Abuse
              </router-link>
            </li>
            <li>
              <router-link
                class="text-white"
                to="#"
              >
                Privacy
              </router-link>
            </li>
          </ul>
          <br><br><br><br>
          <div class="mt-4">
            <a
              target="_blank"
              href="https://www.facebook.com/"
              class="btn btn-neutral btn-icon-only btn-round btn-lg"
              data-toggle="tooltip"
              data-original-title="Like us"
            >
              <i class="fa fa-facebook-square" />
            </a>
            <!-- <a
              target="_blank"
              href="https://www.instagram.com/"
              class="btn btn-neutral btn-icon-only btn-round btn-lg"
              data-toggle="tooltip"
              data-original-title="Follow us"
            >
              <i class="fa fa-instagram" />
            </a> -->
            <!-- <a
              target="_blank"
              href="https://twitter.com/"
              class="btn btn-neutral btn-icon-only btn-round btn-lg"
              data-toggle="tooltip"
              data-original-title="Follow us"
            >
              <i class="fa fa-twitter" />
            </a> -->
          </div>
        </div>
        <div class="col-lg-4 col-sm-6 ">
          <ul class="footer-links">
            <li>
              <router-link
                class="text-white"
                to="/"
              >
                Home
              </router-link>
            </li>
            <li>
              <router-link
                class="text-white"
                to="/happy-hour"
              >
                Happy Hour
              </router-link>
            </li>
            <li>
              <router-link
                class="text-white"
                to="/live-music"
              >
                Live Music
              </router-link>
            </li>
            <!-- <li>
              <router-link
                class="text-white"
                to="/deals-events"
              >
                Events (sponsored)
              </router-link>
            </li> -->
            <li>
              <router-link
                class="text-white"
                to="#"
              >
                Games Arcade
              </router-link>
            </li>
            <li>
              <router-link
                class="text-white"
                to="#"
              >
                Sports Bar
              </router-link>
            </li>
            <li>
              <router-link
                class="text-white"
                to="#"
              >
              Karaoke 
              </router-link>
            </li>
          </ul>
        </div>
        <div class="col-lg-4  col-sm-12">
          <ul class="footer-links">
            <!-- <li>
              <router-link
                class="text-white"
                to="/advertise"
              >
                Advertise
              </router-link>
            </li> -->
            <li>
              <router-link
                class="text-white"
                to="/about-us"
              >
                About Us
              </router-link>
            </li>
            <li>
              <router-link
                class="text-white"
                to="/contact-us"
              >
                Contact Us
              </router-link>
            </li>
          </ul>
        </div>
      </div>
      <!-- Copyright -->
      <div class="row">
        <div class="col-md-12">
          <div class="copyrights text-white">
            © 2021 Thriggle.com. All rights reserved.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
};
</script>
