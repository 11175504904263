
export const menus = [
	// {
	// 	state: '/',
	// 	name: 'HOME',
	// 	type: 'link',
	// },
	
	{
		state: '/happy-hour',
		name: 'HAPPY HOUR',
		type: 'link',
	}, 
	{
		state: '/sports-bars',
		name: 'SPORTS BARS',
		type: 'link',
	}, 
	{
		state: '/about-us',
		name: 'ABOUT',
		type: 'footer',
	}, 
	// {
	// 	state: '/login',
	// 	name: 'LOGIN/SIGN UP',
	// 	type: 'login',
	// }, 
	{
		state: '/live-music',
		name: 'LIVE MUSIC',
		type: 'link',
	}, 
	{
		state: '/karaoke',
		name: 'KARAOKE',
		type: 'link',
	}, 
	{
		state: '/games',
		name: 'GAMES',
		type: 'link',
	}, 
	{
		state: '/events',
		name: 'EVENTS',
		type: 'link',
	}, 
	
	
]

// export default menus;